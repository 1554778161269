.slider {
    transition: background-image 0.5s ease-in-out;
    z-index: 3;
    top: 50px;
    position: relative;
    mask-image: url(../../assets/images/supportimages.png);
    width: 100%;
    height: 870px;
    box-sizing: border-box;
    margin-top: 120px;
    margin-bottom: 80px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-size: cover;
}

.slide {
    transition: 0.5s;
    overflow: hidden;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.divzone {
    width: 600px;
    height: 750px;
    overflow: hidden;
}

.zoneSlider {
    top: -2000px;
    letter-spacing: 5px;
    font-weight: 200;
    font-family: "Poppins-extralight";
    position: relative;
    width: auto;
    min-height: 250px;
    transition: 0.5s;
    background-repeat: no-repeat;
    color: rgba(255, 255, 255, 0.6);
    z-index: 50;
    font-size: 80px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lui {
    color: white;
}

#divzone:nth-child(10) {
    color: white;
}

.start {
    color: white;
}

.zoneSlider:hover {
    font-size: 90px;
    letter-spacing: 10px;
}

#goTop {
    cursor: pointer;
    z-index: 51;
    position: absolute;
    top: 0;
    height: 380px;
    left: 15%;
    width: 70%;
    background: none;
    border: none;
    outline: none;
}

#goBottom {
    cursor: pointer;
    z-index: 51;
    bottom: 0;
    position: absolute;
    height: 380px;
    left: 15%;
    width: 70%;
    background: none;
    border: none;
    outline: none;
}

.dotsdiv {
    left: 0;
    position: absolute;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dots {
    background: white;
    height: 60px;
    width: 120px;
}

.dots:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.zero {
    opacity: 1;
    background: url(../../assets/images/photoclub.jpg);
    background-size: cover;
}

.one {
    opacity: 0.5;
    background: url(../../assets/images/animaux.jpg);
    background-size: cover;
}

.two {
    opacity: 0.5;
    background: url(../../assets/images/art.jpg);
    background-size: cover;
}

.three {
    opacity: 0.5;
    background: url(../../assets/images/avion.jpg);
    background-size: cover;
}

.four {
    opacity: 0.5;
    background: url(../../assets/images/nature.jpg);
    background-size: cover;
}

.five {
    opacity: 0.5;
    background: url(../../assets/images/viesociale.jpg);
    background-size: cover;
}

.six {
    opacity: 0.5;
    background: url(../../assets/images/voyages.jpg);
    background-size: cover;
}

.seven {
    opacity: 0.5;
    background: url(../../assets/images/france.jpg);
    background-size: cover;
}

.eight {
    opacity: 0.5;
    background: url(../../assets/images/famille.jpg);
    background-size: cover;
}

@media (max-width: 1250px) {
    .slider{
        margin-bottom: -80px;
        margin-top: 0;
    }
}

@media (max-width: 800px) {
    .slider {
        background-position: 50%;
    }

    .zoneSlider {
        font-size: 60px;
    }

    #goTop {
        left: 20%;
        width: 60%;
    }
    
    #goBottom {
        left: 20%;
        width: 60%;
    }

    .zoneSlider:hover {
        font-size: 60px;
        letter-spacing: 5px;
    }

    .dots {
        height: 65px;
        width: 90px;
    }
}

@media (max-width: 600px) {
    .slider {
        background-position: 50%;
    }

    .divzone {
        width: 75%;
        margin-left: 20%;
    }

    .zoneSlider {
        font-size: 40px;
    }

    #goTop {
        width: 85%;
    }
    
    #goBottom {
        width: 85%;
    }

    .zoneSlider:hover {
        font-size: 40px;
        letter-spacing: 5px;
    }

    .dotsdiv {
        height: 600px;
    }

    .dots {
        height: 90px;
        width: 90px;
    }
}

@media (max-width: 450px) {
    .slider {
        background-position: 50%;
    }

    .divzone {
        width: 75%;
        margin-left: 20%;
    }

    .zone {
        font-size: 30px;
    }

    #goTop {
        margin-left: 5%;
        width: 75%;
    }
    
    #goBottom {
        margin-left: 5%;
        width: 75%;
    }

    .zone:hover {
        font-size: 30px;
        letter-spacing: 5px;
    }

    .dots {
        height: 90px;
        width: 90px;
    }
}

@media (max-width: 400px) {
    .slider {
        background-position: 50%;
    }

    .divzone {
        width: 75%;
        margin-left: 20%;
    }

    .zoneSlider {
        font-size: 32px;
    }

    .zoneSlider:hover {
        font-size: 32px;
        letter-spacing: 5px;
    }

    .dots {
        height: 90px;
        width: 70px;
    }
}
