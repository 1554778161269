.albumMainNav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;

    max-width: 90%;
}

.albumNavMenuButton {
    margin: 1rem;
    min-width: 18rem;
}

/* TABLET */
@media (min-width: 766px) and (max-width: 1026px) {
    .albumNavMenuButton {
        margin: 1rem;
        min-width: 12rem;
    }
}


/* SMALL AND REGULAR PHONES */
@media (max-width: 766px) {
    .albumMainNav {
        flex-direction: column;
    }

    .albumNavMenuButton {
        min-width: 14rem;
        margin-bottom: 2rem;
    }
}