#modalExt {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);

    overflow-y: scroll;
}

#modalInt {
    height: calc(100% - 6rem);
    width: clac(100% - 16rem);
    z-index: 4;
    margin: 3rem 8rem 3rem 8rem;
}

#modalClear {
    position: fixed;
    z-index: 5;
    top: 2rem;
    right: 3rem;
    color: white;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
}


@media (orientation: landscape) and (max-width: 1026px) {
    #modalInt {
        height: calc(100% - 8rem);
        width: clac(100% - 8rem);
        margin: 4rem 4rem 4rem 4rem;
    } 

    #modalClear {
        top: 1rem;
        right: 1rem;
    }
}

/* TABLET AND PHONES */
@media (orientation: portrait) and (max-width: 1026px) {
    #modalInt {
        height: calc(100% - 16rem);
        width: clac(100% - 4rem);
        margin: 8rem 2rem 8rem 2rem;
    } 
    
    #modalClear {
        top: 1rem;
        right: 1rem;
    }
}