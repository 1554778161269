#carroussel {
    position: relative;
    height: calc(100vh - 4rem);
    width: calc(100vw - 12rem);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carrousselPicture {
    max-width: 90vw;
    max-height: 80vh;
    object-fit: contain;
}

.carrousselLikeContainer {
    position: absolute;
    bottom: 0;
    right: calc(50% - 2rem);
    width: 4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carrousselLikeCount {
    font-size: 2rem;
    margin-right: 1rem;
}

.carrousselLikeIcon {
    width: 2.6rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.carrousselLikeIconSelected {
    transform: scale(1.1);
}

.carrousselLikeIcon:hover {
    transform: scale(1.1);
}

.carrousselLeftArrow {
    top: calc(40%);
    left: 0;
}

.carrousselRightArrow {
    top: calc(40%);
    right: 0;
}

.carrousselArrow {
    position: absolute;
    width: 2.4rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.carrousselArrow:hover {
    transform: scale(1.1);
}

.carrousselSpinner {
    position: absolute;
    top: calc(50% - 1.5rem);
    right: 0;
}


/* TABLET */
@media (orientation: portrait) and (min-width: 766px) and (max-width: 1026px) {
    #carroussel {
        height: calc(100vh - 16rem);
        width: calc(100vw - 4rem);
        justify-content: center;
    }

    .carrousselPicture {
        max-width: 80vw;
        max-height: 80vh;
    }

    .carrousselLeftArrow {
        top: 90%;
        left: 15%;
    }
    
    .carrousselRightArrow {
        top: 90%;
        right: 15%;
    }
}

@media (orientation: landscape) and (max-width: 1400px) {
    #carroussel {
        height: 100%;
        width: 100%;
        justify-content: center;
    }

    .carrousselLikeContainer {
        bottom: 0;
        right: -1.4rem;
    }

    .carrousselLikeCount {
        margin-right: 0.4rem;
    }

    .carrousselLeftArrow {
        left: -2rem;
        top: calc(50% - 1.2rem);
    }
    
    .carrousselRightArrow {
        right: -2rem;
        top: calc(50% - 1.2rem);
    }
    
    .carrousselPicture {
        max-width: 70vw;
        max-height: 80vh;
    }

    .carrousselArrow {
        width: 1.6rem;
    }
}


/* SMALL AND REGULAR PHONES */
@media (orientation: portrait) and (max-width: 766px) {
    #carroussel {
        height: calc(100vh - 16rem);
        width: calc(100vw - 4rem);
        justify-content: center;
    }

    .carrousselLikeContainer {
        bottom: -20%;
    }

    .carrousselLeftArrow {
        top: 110%;
        left: 5%;
    }
    
    .carrousselRightArrow {
        top: 110%;
        right: 5%;
    }
    
    .carrousselPicture {
        max-width: 80vw;
        max-height: 60vh;
    }

    .carrousselArrow {
        width: 1.6rem;
    }
}