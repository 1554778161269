.perroquet {
  z-index: 3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 1000px;
  background: url(../../assets/images/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 200px;
  margin-bottom: 150px;
  background-position: 60% center;
}

/* CONTACT */
#website {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.success, .failure{
  text-align: center;
  visibility: hidden;
}

.success{
  position: relative;
  top: 15px;
  color: rgb(77, 189, 77);
}

.failure{
  position: relative;
  top: -65px;
  color: rgb(255, 57, 57);
}

textarea, .contactInput {
  outline: none;
}

#contactform {
  padding: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.contactInput, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;
  border-bottom: 1px solid white;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 16px;
  resize: vertical;
  background-color: transparent;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
}

textarea {
  height: 200px;
  -webkit-appearance: textfield;
  resize: none;
}

.contactPortfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  z-index: 3;
  position: relative;
  left: 15%;
  border-radius: 40px;
  width: 450px;
  height: 600px;
  background: rgba(0, 0, 0, 0.47);
  padding: 20px;
}

.contactPortfolio .contactTitle, .contactInput, textarea, ::placeholder {
  color: white;
}

.contactPortfolio ::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contactPortfolio .contactTitle {
  margin-top: 80px;
  text-align: center;
}

button[type=submit] {
  text-align: center;
}

.contactPortfolio .contactInput:focus {
  padding: 10px 0px 10px 0px;
  transition: padding 0.5s;
}

.contactPortfolio textarea:focus {
  padding: 10px 0px 10px 0px;
  transition: padding 0.5s;
}

.contactInput:-webkit-autofill, .contactInput:-webkit-autofill:hover, .contactInput:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  border: 0px solid black;
  border-bottom: 2px solid rgb(98, 228, 65);
  -webkit-text-fill-color: white;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.contactInput:focus, textarea:focus {
  border: 0px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, #0051EF, #90DEFF 100%);
  border-image-slice: 1;
}



@media (max-width: 1000px) {
  .contactPortfolio {
    left: 12%;
    width: 70%;
  }
}

@media (max-width: 500px) {
  .contactform {
    padding: 5px;
  }
  .contactPortfolio {
    left: 6%;
    width: 80%;
  }
}

@media (max-width: 400px) {
  .contactPortfolio {
    left: 4.5%;
    width: 80%;
  }
}