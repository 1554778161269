.pictureAlbumContainer {
    position: relative;

    width: calc(25vw - 4rem);
    height: calc(25vw - 1rem);

    cursor: pointer;
}

.pictureAlbumDelete {
    position: absolute;
    top: .5rem;
    left: .5rem;
    height: 2.6rem;
    width: 2.6rem;
    z-index: 1;

    transition: all .2s ease-in-out;
}

.pictureAlbumDeleteLoading {
    position: absolute;
    top: 1rem;
    left: .5rem;

    z-index: 1;
}

.pictureAlbumEdit {
    position: absolute;
    top: .5rem;
    right: .5rem;
    height: 2.6rem;
    width: 2.6rem;
    z-index: 1;

    transition: all .2s ease-in-out;
}

.pictureFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pictureLikeContainer {
    width: 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pictureLike {
    transition: all .2s ease-in-out;
    width: 2rem;
}   

.pictureLike:hover {
    transform: scale(1.1);
}

.liked {
    transform: scale(1.1);
}

.pictureAlbum {
    position: absolute;
    top: 0;
    left: 0;

    width: calc(25vw - 4rem);
    height: calc(25vw - 4rem);
    object-fit: cover;

    transition: all .2s ease-in-out;
}

.pictureAlbum:hover {
    transform: scale(1.02);
}


/* TABLET */
@media (min-width: 600px) and (max-width: 1026px) {
    .pictureAlbumContainer {
        width: calc(50vw - 4rem);
        height: calc(50vw - 1rem);
    }

    .pictureAlbum {
        width: calc(50vw - 4rem);
        height: calc(50vw - 4rem);
    }
    
}

/* SMALL AND REGULAR PHONES */
@media (max-width: 600px) {
    .pictureAlbumContainer {
        width: calc(100vw - 4rem);
        height: calc(100vw - 1rem);
    }

    .pictureAlbum {
        width: calc(100vw - 4rem);
        height: calc(100vw - 4rem);
    }
}