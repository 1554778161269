.basicInput {
    width: calc(100% - 2rem);
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 3rem;
    border: rgba(0, 0, 0, 0.7) .2rem solid;

    transition: .3s;
    font-size: 1.6rem;
}

.basicInput:focus {
    border: var(--primary) .2rem solid;
}