#exportForm {
    width: 100%;
    background-color: white;
    overflow-y: auto;
}

#exportFormContent {
    padding: 3%;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
    grid-template-areas: 
        "pictureLabel exportPicture exportPicture exportPicture exportPicture"
        "multiplePicturesLabel multiplePictures multiplePictures . ."
        "priorityLabel priority . . ."
        "zoneLabel zoneInput . . ."
        "tagsLabel tags tags tags tags"
        ". . button . ."
        ". . message . ."
    ;
    gap: 2rem;
}

.pictureLabel {
    grid-area: pictureLabel;
}

#exportPicturesContainer {
    grid-area: exportPicture;

    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
}

.exportPicture {
   width: 200px;
   height: 200px;
   margin: 1rem; 
   margin-right: 3rem;
   flex-shrink: 0;
}

.multiplePicturesLabel {
    grid-area: multiplePicturesLabel;
}

.multiplePictures {
    grid-area: multiplePictures;
    align-self: center;
    font-size: 1.2rem;
}

.priorityLabel {
    grid-area: priorityLabel;
}

.priority {
    grid-area: priority;
}

.zoneLabel {
    grid-area: zoneLabel;
}

.zoneInput {
    grid-area: zoneInput;
}

.tagsLabel {
    grid-area: tagsLabel;
}

#tagsContainer {
    grid-area: tags;
}

.messageTagsContainer {
    grid-area: tags;
    
    align-self: center;
}

.exportButton {
    grid-area: button;
}

.exportMessage {
    grid-area: message;
    display: flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
}
