.parallax1 {
  top: 20px;
  position: absolute;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 1000px;
  background: url(../../assets/images/parallax1.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.parallax21 {
  top: -40px;
  position: absolute;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 200px;
  background: url(../../assets/images/parallax21.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.parallax22 {
  top: 750px;
  position: absolute;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 200px;
  background: url(../../assets/images/parallax22.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.parallax31 {
  top: 2400px;
  position: absolute;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 200px;
  background: url(../../assets/images/parallax31.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.parallax32 {
  top: 3150px;
  position: absolute;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 200px;
  background: url(../../assets/images/parallax32.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.parallax4 {
  position: absolute;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 200px;
  background: url(../../assets/images/parallax4.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: 3350px;
  background-position: top bottom;
}

@media (min-width: 1600px) {
  .parallax22 {
    top: 870px;
  }
  .parallax31 {
    top: 2500px;
  }
  .parallax32 {
    top: 3280px;
  }
  .parallax4 {
    top: 3500px;
  }
}

@media (max-width: 1250px) {
  .parallax1 {
    height: 850px;
    background-position: 70% bottom;
  }
  .parallax21 {
    height: 150px;
  }
  .parallax22 {
    height: 150px;
    top: 620px;
  }
  .parallax31 {
    height: 150px;
    top: 2000px;
  }
  .parallax32 {
    top: 2700px;
    height: 150px;
  }
  .parallax4 {
    top: 2890px;
    height: 150px;
  }
}

@media (max-width: 1030px) {
  .parallax31 {
    height: 150px;
    top: 2150px;
  }
  .parallax32 {
    top: 2770px;
    height: 150px;
  }
  .parallax4 {
    top: 3070px;
    height: 150px;
  }
}

@media (max-width: 1000px) {
  .parallax31 {
    height: 150px;
    top: 2180px;
  }
  .parallax22 {
    visibility: hidden;
  }
  .parallax32 {
    visibility: hidden;
  }
  .parallax4 {
    top: 3100px;
    height: 150px;
  }
}

@media (max-width: 800px) {
  .parallax1 {
    height: 670px;
    background-position: 70% bottom;
  }
  .parallax21 {
    height: 100px;
    top: -20px;
  }
  .parallax31 {
    visibility: hidden;
  }
  .parallax4 {
    top: 2950px;
    height: 150px;
  }
}

@media (max-width: 500px) {
  .parallax1 {
    top: 320px;
    height: 670px;
    background-position: 100% bottom;
  }
  .parallax21 {
    background: url(../../assets/images/parallax21.svg);
    top: 0px;
  }
  .parallax31 {
    visibility: hidden;
  }
  .parallax4 {
    top: 3300px;
    height: 150px;
  }
}