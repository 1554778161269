/* width */
::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #99999983;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #359EFF;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2a82d4;
  }