.albumHeader {
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.albumHeaderPicture {
    position: absolute;
    object-fit: cover;
    object-position: bottom;
    height: 40vh;
    width: 100%;
    z-index: -1;
}

.exportIcons {
    display: flex;

    position: absolute;
    top: 2rem;
    left: 2rem;
}

.albumHeaderExportIcon {
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;
    transition: all .2s ease-in-out;
}

.albumHeaderTitle {
    font-size: 5rem;
    text-align: center;
}

.albumHeaderExportIcon:hover {
    transform: scale(1.1);
}


/* TABLET AND PHONES */
@media (max-width: 1026px) {
    .albumHeader {
        height: 50vh;
    }

    .albumHeaderPicture {
        height: 50vh;
    }
}

/* PHONES */
@media (max-width: 766px) {
    .albumHeaderTitle {
        font-size: 3rem;
    }
}

@media (max-width: 350px) {
    .albumHeaderTitle {
        font-size: 2rem;
    }
}