.codeurs{
  width: 100%;
  top: -150px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  
}
.robin{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;

}
.julien{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
}

.buttoncodeurs{
  -webkit-appearance: initial;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.contactcodeurs {
  border: 0;
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  font-size: 18px;
  padding-bottom: 25px;
  padding-top: 25px;
  outline: none;
  color: white;
  background-color: rgb(53, 105, 184);
  transition: background-color 0.5s;
}

.contactcodeurs:hover {
  background-color: rgb(94, 153, 241);
}

.divSendButton {
  display: flex;
  justify-content: center;
  align-content: center;
}



@media (max-width: 1030px) {
  .codeurs{
    flex-wrap: wrap;
  }
  .codeurs h3 {
    order: 1;
    width: 100%;
    height: 80px;
  }
  .robin {
    order: 2;
    /* height: 110px; */
  }
  .julien {
    order: 3;
    /* height: 110px; */
  }
}

@media (max-width: 500px) {
  .codeurs h3 {
    order: 1;
    height: 30px;
  }
  .robin {
    order: 2;
    height: 110px;
  }
  .julien {
    margin-top: 80px;
    order: 3;
    height: 110px;
  }
  .codeurs {
    height: 400px;
    align-items: space-around;
    margin-top: 170px;
    margin-bottom: -50px;
    flex-direction: column;
  }
}
