html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.menuContact{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}

.title3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
}

.title4 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: white;
    border-bottom: solid white 2px;
}

.poppins18 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: white;
    line-height: 40px;
}

.poppins15 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: white;
    line-height: 40px;
}

.zone {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: 50px;
    color: white;
    line-height: 40px;
}

.poppinsRegular {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: white;
    line-height: 40px;
}

.noLinkStyle{
    text-decoration: none;
}

@media (max-width: 1210px) {
    .title4{
        font-size: 23px;
    }
}