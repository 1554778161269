.accueil {
  position: relative;
  max-width: 100%;
  height: 1000px;
  background: url(../../assets/images/imageaccueilpimp.png);
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  background-position: bottom;
}

.logo {
  z-index: 2;
  position: absolute;
  width: 45%;
  top: 280px;
  right: 5%;
  ;
}

@media (max-width: 1250px) {
  .accueil {
    height: 850px;
    background-position: 30% bottom;
  }
  .logo {
    width: 400px;
  }
}

@media (max-width: 800px) {
  .accueil {
    height: 680px;
    background-position: 30% bottom;
  }
  .logo {
    top: 200px;
    width: 300px;
  }
}

@media (max-width: 500px) {
  .accueil {
    background: url(../../assets/images/phonehome.png);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    height: 1000px;
    background-position: center center;
  }
  .logo {
    top: 750px;
    width: 70%;
    left: 15%;
  }
}