.bio {
    position: relative;
    max-width: 100%;
    height: 900px;
    background: url(../../assets/images/fond1.png);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    margin-bottom: 100px;
    box-sizing: border-box;
}

.pepe {
    z-index: 2;
    position: absolute;
    width: 30%;
    top: 120px;
    right: 5%;
    box-sizing: border-box;
    overflow: hidden;
}

.textbio {
    z-index: 3;
    position: absolute;
    left: 10%;
    top: 350px;
    width: 600px;
    text-align: center;
}

@media (min-width: 1600px) {
    .bio{
        height: 1000px;
    }
}

@media (max-width: 1450px) {
    .pepe {
        z-index: 2;
        position: absolute;
        width: 420px;
        top: 120px;
        right: 0%;
    }
}

@media (max-width: 1250px) {
    .bio {
        height: 750px;
        margin-bottom: 0px;
    }
    .textbio {
        top: 270px;
    }
    .textbio .paragraphe {
        font-size: 17px;
    }
    .pepe {
        z-index: 2;
        position: absolute;
        width: 320px;
        top: 120px;
        right: 0%;
    }
}

@media (max-width: 1000px) {
    .bio {
        height: 800px;
    }
    .textbio {
        width: 50%;
        top: 270px;
        left: 5%;
    }
    .textbio .paragraphe {
        font-size: 16px;
    }
    .pepe {
        z-index: 2;
        position: absolute;
        width: 350px;
        top: 120px;
        right: 5%;
        ;
    }
}

@media (max-width: 800px) {
    .textbio {
        width: 35%;
        top: 250px;
        left: 8%;
    }
    .textbio .paragraphe {
        font-size: 14px;
    }
    .pepe {
        z-index: 2;
        position: absolute;
        width: 350px;
        top: 120px;
        right: 1%;
    }
}

@media (max-width: 600px) {
    .textbio {
        top: 200px;
        left: 5%;
    }
    .pepe {
        width: 320px;
        top: 120px;
    }
}

@media (max-width: 500px) {
    .textbio {
        width: 45%;
        top: 200px;
        left: 10%;
    }
    .textbio .paragraphe {
        font-size: 14px;
    }
    .pepe {
        z-index: 2;
        position: absolute;
        width: 300px;
        top: 120px;
        right: -40%;
    }
    .bioOver {
        width: 100%;
        height: 1500px;
        overflow: hidden;
        margin-bottom: -680px;
    }
}

@media (max-width: 350px) {
    .textbio {
        width: 40%;
        top: 150px;
        left: 7%;
    }
    .pepe {
        z-index: 2;
        position: absolute;
        width: 300px;
        top: 120px;
        right: -45%;
    }
}