.albumZones {
    width: 100%; 
    margin-bottom: 4rem;

    background-color: var(--secondary-background);

    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.albumZoneItem {
    position: relative;
    cursor: pointer;
}

.albumZonePicture {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;

    transition: all .2s ease-in-out;
}

.albumZoneTitle {
    position: absolute;
    top: 1rem;
    left: 2rem;

    transition: all .2s ease-in-out;
}

.albumZonePicture:hover {
    filter: brightness(1.1);
}

.albumZonePicture:hover ~ .albumZoneTitle {
    transform: scale(1.1);
}

.albumZoneTitle:hover {
    transform: scale(1.1);
}


/* TABLET */
@media (min-width: 766px) and (max-width: 1026px) {
    .albumZones {
        grid-template-columns: repeat(3, 1fr);
    }

    .albumZoneTitle {
        top: calc(50% - 1rem);
        left: calc(50% - 5rem);
        text-align: center;

        height: 2rem;
        width: 10rem;
    }
}


/* SMALL AND REGULAR PHONES */
@media (max-width: 766px) {
    .albumZones {
        grid-template-columns: repeat(2, 1fr);
    }

    .albumZoneTitle {
        top: calc(50% - 1rem);
        left: calc(50% - 4rem);
        text-align: center;

        height: 2rem;
        width: 8rem;
    }
}