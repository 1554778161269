#nav{
  visibility: visible;
    background: transparent;
    width: 100%;
    height: 80px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 50;
    transition: all 0.1s ease-in-out;
  }

#nav.active{
  visibility: visible;
  background: #53719D;
}

#nav ul{
  visibility: visible;
    height: 80px;
    width: 100%;
    top: 0px;
    margin-right: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}
#nav li{
  visibility: visible;
  cursor: pointer;
    color: white;
    display: flex;
    margin-right: 10%;
    transition: all .3s ease-in-out;
}

#nav li:hover {
  transform: scale(1.1);
}

#nav li a{
  text-decoration: none;
}


.focal{
  visibility: visible;
    margin: 0;
    z-index: 4;
    position: absolute;
    height: 70px;
    left: 5%;
  }

@media (max-width: 800px) {
  #nav li{
    margin-right: 10%;
  }
  .focal{
    height: 60px;
  }

}

@media (max-width: 500px) {
    #nav{
      visibility: hidden;
    }
  
  #nav.active{
    visibility: hidden;
  }
  
  #nav ul{
    visibility: hidden;
  }
  #nav li{
    visibility: hidden;
  }
  .focal{
    visibility: hidden;
    }
  }